<template>
  <div class="challenge">
    <h1 class="card-title">Règlement</h1>

    <Challenge />
  </div>
</template>

<script>
import Challenge from "@/components/challenge.vue";

export default {
  name: "challenge",
  components: {
    Challenge,
  },
};
</script>


